
import { Options, Prop } from "vue-property-decorator";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import { mixins } from "vue-class-component";
import UserMixin from "@/mixins/UserMixin";
import TheoryPreExamsAnswer from "@/components/Pages/TheoryPreExams/TheoryPreExamsAnswer.vue";
import useRequest from "@/composable/useRequest";
import { IFinishedPreExamQuestion } from "@/interfaces/IFinishedPreExamQuestion";
import groupBy from "lodash/groupBy";
import { IQuestion } from "@/interfaces/IQuestion";
import { IAnswer } from "@/interfaces/IAnswer";
import { IPreExamQuestion } from "@/interfaces/IPreExamQuestion";
import { IOption } from "@/interfaces/IOption";
import { IQuestionWithAnswer } from "@/interfaces/IQuestionWithAnswer";

@Options({
  components: {
    TheoryPreExamsAnswer,
    DefaultLayout,
  },
})
export default class TheoryPreExamsResult extends mixins(UserMixin) {
  public name = "TheoryPreExamsResult";

  @Prop({ default: 0 })
  public preExamProgress!: any;

  private get theoryPreExamsAnswerSuccess() {
    return this.theoryPreExam?.successfullyPassed;
  }

  private selectedQuestion: any = {};
  private showAnswer = false;

  private req = useRequest<IFinishedPreExamQuestion>();

  private answersByParagraphColumnWidthStyle(answersByParagraph: any): any {
    let longestParagraph = 0;
    for (const paragraph in answersByParagraph) {
      const paragraphLength = answersByParagraph[paragraph].answersStatus.length;
      if (paragraphLength > longestParagraph) {
        longestParagraph = paragraphLength;
      }
    }
    return { width: 100 / longestParagraph + "%" };
  }

  private selectQuestion(id: number, type: number): void {
    this.selectedQuestion = this.theoryPreExamQuestions.find((q: any) => q.id == id);
    this.showAnswer = true;
  }

  private onAnswerClose(): void {
    this.showAnswer = false;
  }

  private get theoryPreExam(): IFinishedPreExamQuestion | null {
    //@ts-ignore
    return this.req.data;
  }

  private get theoryPreExamQuestions(): IQuestionWithAnswer[] {
    const theoryPreExamQuestions = this.theoryPreExam?.theoryQuestions || [];
    return theoryPreExamQuestions.map((theoryPreExamQuestion: IQuestion): IQuestionWithAnswer => {
      const foundTheoryPreExamAnswer = this.theoryPreExamAnswers.find(
        (theoryPreExamAnswer: IAnswer) => theoryPreExamAnswer.theoryQuestionId === theoryPreExamQuestion.id
      );

      const userAnswerPositions = foundTheoryPreExamAnswer?.answer?.split(";") || [];
      const correctAnswerPositions = theoryPreExamQuestion?.correctAnswer?.split(";") || [];

      const answer = theoryPreExamQuestion.options.map((option: IOption) => {
        const correctAnswerValue = correctAnswerPositions.indexOf(String(option.position)) > -1;
        const userAnswerValue = userAnswerPositions.indexOf(String(option.position)) > -1;

        return {
          position: option.position,
          // correct question answer
          value: correctAnswerValue,
          // comparison user answer value and correct answer value
          correct: correctAnswerValue === userAnswerValue,
          // user value
          answer: userAnswerValue,
        };
      });
      return {
        ...theoryPreExamQuestion,
        theoryPreExamAnswer: foundTheoryPreExamAnswer,
        answer:
          theoryPreExamQuestion.questionType === "FREE_TEXT"
            ? [
                {
                  answer: foundTheoryPreExamAnswer?.answer,
                  correct: foundTheoryPreExamAnswer?.correct,
                  value: correctAnswerPositions?.[0],
                },
              ]
            : answer,
      };
    });
  }

  private get groupedTheoryPreExamQuestions() {
    const preExamQuestionMap = new Map<number, IPreExamQuestion>();

    for (const theoryPreExamQuestion of this.theoryPreExamQuestions) {
      const paragraphId = theoryPreExamQuestion.paragraph.id;

      const isCorrect = Boolean(theoryPreExamQuestion.theoryPreExamAnswer?.correct);

      if (!preExamQuestionMap.has(paragraphId)) {
        preExamQuestionMap.set(paragraphId, {
          questionClass: theoryPreExamQuestion.questionClass,
          name: theoryPreExamQuestion.paragraph.name,
          countCorrect: isCorrect ? 1 : 0,
          countIncorrect: isCorrect ? 0 : theoryPreExamQuestion.points,
          answersStatus: [
            {
              id: theoryPreExamQuestion.id,
              correct: isCorrect,
            },
          ],
        });
      } else {
        const preExamQuestion = preExamQuestionMap.get(paragraphId);
        if (preExamQuestion) {
          if (isCorrect) {
            preExamQuestion.countCorrect++;
          } else {
            preExamQuestion.countIncorrect += theoryPreExamQuestion.points;
          }
          preExamQuestion.answersStatus.push({
            id: theoryPreExamQuestion.id,
            correct: isCorrect,
          });
          preExamQuestionMap.set(paragraphId, preExamQuestion);
        }
      }
    }

    return groupBy(Array.from(preExamQuestionMap.values()), (value: IPreExamQuestion) => {
      return this.isBasicQuestion(value) ? "basic" : "specific";
    });
  }

  // TODO: code duplication move to helper
  private isBasicQuestion(question: IPreExamQuestion): boolean {
    return (
      question.questionClass === "G" || question.questionClass === "GM" || question.questionClass === "G,GM" || question.questionClass === "GM,G"
    );
  }

  private get theoryPreExamLicenseClass() {
    return this.theoryPreExam?.studentEducation.name;
  }

  private get theoryPreExamAnswers() {
    return this.theoryPreExam?.answers || [];
  }

  private get points() {
    let incorrectBasicQuestionPoints = 0;
    let incorrectSpecificQuestionPoints = 0;

    for (const basicTheoryPreExamQuestion of this.groupedTheoryPreExamQuestions?.basic || []) {
      incorrectBasicQuestionPoints += basicTheoryPreExamQuestion.countIncorrect;
    }

    for (const specificTheoryPreExamQuestion of this.groupedTheoryPreExamQuestions?.specific || []) {
      incorrectSpecificQuestionPoints += specificTheoryPreExamQuestion.countIncorrect;
    }

    return {
      incorrectBasicQuestionPoints,
      incorrectSpecificQuestionPoints,
      totalPoints: incorrectBasicQuestionPoints + incorrectSpecificQuestionPoints,
    };
  }

  public mounted() {
    const theoryPreExamId = this.$route.params.id;
    this.req.fetch({
      url: `/theory-pre-exams/student/current/finished/${theoryPreExamId}`,
      method: "get",
    });
  }
}
